import { PlatformControllerService, PlatformID } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { getByKey, getWithSuffix, stringTypeCheck } from '@common/envvars';
import { Duration } from '@common/types';
import { getPlatformIDByHostname } from '@common/utils/HostUtil';

import { ApiProps } from './ApiProps';
import { CaptchaProps } from './CaptchaProps';
import { CachedContextData } from './ContextData';
import { CrossPlatformByPlatformID } from './CrossPlatformByPlatformID';

const getCaptchaConfig = (platform: PlatformID): CaptchaProps => {
    const envVars = {
        NEXT_PUBLIC_CAPTCHA_SITE_KEY: false,
    };
    const values = getWithSuffix(platform, envVars);
    return {
        siteKey: values.NEXT_PUBLIC_CAPTCHA_SITE_KEY ?? '',
    };
};

const getApiConfig = (suffix: string | undefined): ApiProps => {
    const envVars = {
        NEXT_SERVER_API_HOST: false,
        NEXT_PUBLIC_API_HOST: true,
        NEXT_PUBLIC_API_TIMEOUT: false,
    };
    const values = suffix ? getWithSuffix(suffix, envVars) : process.env;
    return {
        internalHost: values.NEXT_SERVER_API_HOST ?? '',
        host: values.NEXT_PUBLIC_API_HOST ?? '',
        timeout: Number(values.NEXT_PUBLIC_API_TIMEOUT) || 10 * Duration.second,
    };
};

export const cachedContextDataByHost: Record<string, CachedContextData> = {};

export const getCachedContextData = async (
    hostname: string,
    isServerSide: boolean,
): Promise<CachedContextData> => {
    if (cachedContextDataByHost[hostname]) return structuredClone(cachedContextDataByHost[hostname]);

    const platformID = getPlatformIDByHostname(hostname);
    if (!platformID) throw Error(`Hostname ${hostname} could not be recognized`);

    const apiSuffixByHostname = isServerSide
        ? getByKey('NEXT_SERVER_API_SUFFIX_BY_HOSTNAME', stringTypeCheck)
        : undefined;
    const apiSuffix = apiSuffixByHostname ? apiSuffixByHostname.get(hostname) : undefined;
    const apiConfig = getApiConfig(apiSuffix);

    const { NEXT_PUBLIC_SOCKET_HOST } = apiSuffix
        ? getWithSuffix(apiSuffix, { NEXT_PUBLIC_SOCKET_HOST: false })
        : process.env;
    const socketConfig: ApiProps = {
        host: NEXT_PUBLIC_SOCKET_HOST ?? '',
        timeout: 0,
    };

    const sportmonksF1Config: ApiProps = {
        internalHost: process.env.NEXT_SERVER_SPORTMONKS_F1_HOST ?? '',
        host: process.env.NEXT_PUBLIC_SPORTMONKS_F1_HOST ?? '',
        timeout: Number(process.env.NEXT_PUBLIC_SPORTMONKS_F1_TIMEOUT) || 5 * Duration.second,
    };

    const crossPlatformConfig: CrossPlatformByPlatformID = process.env.NEXT_PUBLIC_CROSS_PLATFORM_CONFIG
        ? JSON.parse(process.env.NEXT_PUBLIC_CROSS_PLATFORM_CONFIG)
        : {
              br: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.bright.nl',
              },
              gp: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.gpblog.com',
              },
              vp: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.voetbalprimeur.nl',
              },
              vpbe: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.voetbalprimeur.be',
              },
              vn: {
                  timeout: 5 * Duration.second,
                  host: 'https://api.voetbalnieuws.nl',
              },
          };

    const captchaConfig = getCaptchaConfig(platformID);

    const contextDataConfig: Pick<CachedContextData, 'config'> = {
        config: {
            api: apiConfig,
            socket: socketConfig,
            sportmonksF1: sportmonksF1Config,
            crossPlatform: crossPlatformConfig,
            captchaConfig: captchaConfig,
        },
    };

    const request = new ApiBaseRequest(contextDataConfig, false);
    const platformService = new PlatformControllerService(request);
    const [platform, contexts] = await Promise.all([
        platformService.getPlatformByPlatform({ platform: platformID }),
        platformService.getContextsPlatformByPlatform({
            platform: platformID,
        }),
    ]);

    // Make sure next.staging.voetbalprimeur.localhost works locally
    if ((process.env.APP_ENV || process.env.NODE_ENV) === 'development') {
        contexts.forEach((context) => {
            if (context.pattern) {
                context.pattern = context.pattern.replace(/\\?\.([\w\{\}\\]*?)\$/, `($1)?\.localhost$`);
            }
        });
    }

    const context =
        contexts.length === 1
            ? contexts.at(0)
            : contexts.find((context) => {
                  // do not cache if it's based on the context slug
                  if (context.slug) return false;
                  return context.pattern && new RegExp(context.pattern, 'i').test(hostname);
              });

    const contextData: CachedContextData = {
        ...contextDataConfig,
        contexts,
        context,
        hostname,
        platform,
        env: {},
    };

    /**
     * process.env.NEXT_PUBLIC_FOO variables are evaluated on build time
     * therefor we'll make them accessible through useContextData
     * See threads like https://github.com/vercel/next.js/discussions/34894
     */
    if (process.env) {
        for (const key in process.env) {
            const value = process.env[key];
            if (/^NEXT_PUBLIC_|^(APP|NODE)_ENV$/.test(key) && value) {
                contextData.env[key] = value;
            }
        }
    }

    cachedContextDataByHost[hostname] = structuredClone(contextData);
    return structuredClone(contextData);
};
