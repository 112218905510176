import { createContext, useContext, useEffect, useState } from 'react';
import JsCookies from 'js-cookie';

import { User } from '@common/clients/api';
import { LocaleID, PlatformID, SubjectID } from '@common/clients/api';
import { ContextData, ResolvedContextData } from '@common/defaults';
import { SupertokensStatus } from '@common/supertokens/SupertokensStatus';
import { checkIfBettingEnabled } from '@common/utils/betting';
import { fetchCurrentUser } from '@web/handlers/fetchCurrentUser';

export const NextContext = createContext<ContextData>({
    allowAds: true,
    allowBetting: true,
    allowTracking: true,
    contexts: [],
    env: {},
    hostname: '',
    isPlatformContext: false,
    magnoliaSessionID: '',
    userAgent: '',
    userID: 0,
    userSessionID: '',
    context: {
        id: 0,
        locale: LocaleID.EN,
        platform: PlatformID.VP,
        slug: '',
        subject: SubjectID.FOOTBALL,
        url: '',
        name: '',
        nameSimple: '',
        twitter: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        youtube: '',
        address: '',
    },
    platform: {
        id: PlatformID.VP,
        name: '',
        subject: SubjectID.FOOTBALL,
        jwplayerKey: '',
    },
    config: {
        api: {
            host: '',
            timeout: 0,
        },
        socket: {
            host: '',
            timeout: 0,
        },
        sportmonksF1: {
            host: '',
            timeout: 0,
        },
        crossPlatform: {},
        captchaConfig: {
            siteKey: '',
        },
    },
    supertokens: {
        status: SupertokensStatus.UNKNOWN,
        sAccessToken: '',
        sRefreshToken: '',
        sFrontToken: '',
        sAntiCsrf: '',
        'st-last-access-token-update': '',
    },
});

let userPromise: Promise<User | undefined> | undefined;

export const useContextData = (): ResolvedContextData => {
    const contextData = useContext(NextContext);
    const [user, setUser] = useState<User | undefined>();

    if (typeof window !== undefined) {
        const userID = JsCookies.get('userID');
        if (userID) {
            contextData.userID = +userID;
        }
    }

    if (user) {
        contextData.user = user;
        if (!checkIfBettingEnabled(user)) {
            contextData.allowBetting = false;
        }
    }

    // TODO: not too happy with this, find a way to efficiently provide the user to the client
    useEffect(() => {
        if (contextData.userID && !contextData.user && contextData.config.api.host) {
            if (!userPromise) {
                userPromise = fetchCurrentUser(contextData, true);
            }
            userPromise.then((user) => {
                if (user) {
                    setUser(user);
                }
            });
        }
    }, [contextData.config.api.host]);

    return contextData as ResolvedContextData;
};
